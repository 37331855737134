export const employer = "Employer";
export var windowlocation;
export var windowPathName;
export const recruiterUserType = 'recruiter';
export const candidateUserType = 'candidate'

export var recaptchaSiteKey = "6Le5sMEpAAAAAJE5jJIAFTSkmK2kWrEGMut7ySRC";
if (typeof window !== 'undefined') {
  // Safe to use the window object
   windowPathName = window.location.pathname;
   windowlocation = window.location;
}


export const getDomainURL = (location, name) => {
  if (typeof window === 'undefined') {
    return null;
  }
  const { hostname, protocol, search } = location;
  let newHost = null;

  switch (hostname?.replace(/^www\./, "")) {
    case "expertscircle.io":
      newHost = name === employer
        ? "employer.expertscircle.io"
        : `candidate.expertscircle.io?userType=${name}`;
      break;
    case "staging.expertscircle.io":
    case "localhost":
      newHost = name === employer
        ? "employer.staging.expertscircle.io"
        : `candidate.staging.expertscircle.io?userType=${name}`;
      break;
    default:
      // Optionally handle unexpected hostnames here
      break;
  }

  if (newHost !== null) {
    // Create the new URL with the updated domain and original query string parameters

      const newUrl = `${protocol}//${newHost}${search}`;
      return newUrl;
  } else {
    // Return the original URL if the domain is not in the list
    return location.href;
  }
};
