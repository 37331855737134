import React from "react";

function LinkedinIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="36"
      height="34"
      viewBox="0 0 36 34"
    >
      <g clipPath="url(#clip0_330_23)">
        <path
          d="M18.29 0C8.63 0 .797 7.613.797 17S8.63 34 18.29 34c9.66 0 17.493-7.613 17.493-17S27.949 0 18.29 0zm-5.083 25.7h-4.26V13.242h4.26V25.7zm-2.13-14.158h-.028c-1.43 0-2.354-.956-2.354-2.151 0-1.223.953-2.153 2.41-2.153 1.457 0 2.354.93 2.382 2.153 0 1.195-.925 2.151-2.41 2.151zM28.568 25.7h-4.26v-6.664c0-1.674-.616-2.816-2.158-2.816-1.176 0-1.877.77-2.185 1.514-.113.266-.14.638-.14 1.01V25.7h-4.26s.055-11.287 0-12.456h4.26v1.764c.566-.849 1.579-2.056 3.839-2.056 2.803 0 4.904 1.78 4.904 5.606v7.142z"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0_330_23">
          <path
            fill="#fff"
            d="M0 0H34.986V34H0z"
            transform="translate(.797)"
          ></path>
        </clipPath>
      </defs>
    </svg>
  );
}

export default LinkedinIcon;
