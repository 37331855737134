import React from 'react';
import './typography.scss';

const Typography = ({
  className = [],
  variant,
  as,
  children,
  strikeThrough = false,
  align,
  noGutter = false,
  dataTestIdPrefix = '',
  borderBottom = false,
  maxWidth = false,
  upperCase = false,
  fontBold = false,
}) => {
  const Component = as || variant || 'p';

  const classes = [
    'typography',
    variant,
    ...className,
    strikeThrough ? 'strikethrough' : '',
    noGutter ? 'no-gutter' : '',
    align ? `align-${align}` : '',
    borderBottom ? 'borderbottom' : '',
    maxWidth ? 'maxwidth' : '',
    upperCase ? 'uppercase' : '',
    fontBold ? 'fontbold' : '',
  ]
    .join(' ')
    .trim();

  return (
    <Component className={classes} data-testid={`${dataTestIdPrefix}-${variant || as}`}>
      {children}
    </Component>
  );
};

export default Typography;
