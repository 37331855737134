// extracted by mini-css-extract-plugin
export var helpsection = "footer-module--helpsection--SGHk8";
export var infoSection = "footer-module--infoSection--yaVY8";
export var footerLogo = "footer-module--footerLogo--rwXj9";
export var infoText = "footer-module--infoText--XO749";
export var title = "footer-module--title--81BhE";
export var helpfulLinksList = "footer-module--helpfulLinksList--O1ICo";
export var socialLinks = "footer-module--socialLinks--cbYSl";
export var helpfulLinkGorup = "footer-module--helpfulLinkGorup--G5TJr";
export var row = "footer-module--row--QOwWT";
export var col = "footer-module--col--IfhPR";
export var socialLinksItem = "footer-module--socialLinksItem--SI1tj";
export var copyright = "footer-module--copyright--peRSN";