import React from "react";

function TwitterIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="36"
      height="34"
      viewBox="0 0 36 34"
    >
      <g clipPath="url(#clip0_330_27)">
        <path
          d="M17.638 0C7.978 0 .145 7.613.145 17s7.833 17 17.493 17c9.66 0 17.492-7.613 17.492-17S27.297 0 17.638 0zm7.987 13.255c.008.167.011.335.011.504 0 5.156-4.038 11.102-11.424 11.102-2.267 0-4.377-.646-6.154-1.753.314.036.634.054.958.054a8.205 8.205 0 004.987-1.67c-1.758-.032-3.24-1.16-3.751-2.71a4.105 4.105 0 001.813-.067c-1.837-.358-3.221-1.936-3.221-3.825v-.05c.541.293 1.16.469 1.819.488-1.078-.699-1.787-1.894-1.787-3.248 0-.715.199-1.384.544-1.961a11.512 11.512 0 008.276 4.077 3.792 3.792 0 01-.104-.89c0-2.154 1.798-3.901 4.015-3.901 1.155 0 2.198.474 2.93 1.232a8.199 8.199 0 002.55-.947 3.94 3.94 0 01-1.765 2.159 8.192 8.192 0 002.306-.615 8.038 8.038 0 01-2.003 2.02z"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0_330_27">
          <path
            fill="#fff"
            d="M0 0H34.986V34H0z"
            transform="translate(.145)"
          ></path>
        </clipPath>
      </defs>
    </svg>
  );
}

export default TwitterIcon;
