import React from 'react'
import { ThemeProvider } from '@mui/material/styles';
import { createTheme } from '@mui/material/styles';

import '../../styles/global.scss'
import * as classes from './layout.module.scss'
import Header from './Header/Header'
import Footer from './Footer/Footer'


const theme = createTheme({
  palette: {
    primary: {
      main: '#492D21'
    },
    secondary: {
      main: "#FCCC4F"
    }
  },
  typography: {
    body1: {
      fontSize: 18,
      fontStyle: 'normal',
      fontWeight: 600,
      lineHeight: "27px"
    }
  }
});

export default function Layout({children, mode}) {
  return (
    <ThemeProvider theme={theme}>
      <div className={classes.layoutRoot}>
        <Header mode={mode} />
        <main className={classes.mainContent}>
          {children}
        </main>
        {mode !== "empty" && <div style={{marginTop: 'auto'}}><Footer /></div>}
      </div>
    </ThemeProvider>
  )
}
