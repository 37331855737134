import React from "react";

function FacebookIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="36"
      height="34"
      viewBox="0 0 36 34"
    >
      <path
        d="M35.478 17c0-9.39-7.83-17-17.493-17C8.323 0 .493 7.61.493 17s7.83 17 17.492 17c.103 0 .205 0 .308-.007V20.765h-3.758V16.51h3.758v-3.135c0-3.632 2.282-5.611 5.617-5.611 1.599 0 2.972.113 3.369.166v3.798h-2.296c-1.811 0-2.167.837-2.167 2.066v2.709h4.34l-.568 4.257h-3.772v12.584c7.312-2.04 12.662-8.58 12.662-16.343z"
      ></path>
    </svg>
  );
}

export default FacebookIcon;
